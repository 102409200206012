<template>
  <!-- form -->
  <b-dropdown
    v-if="image"
    :id="'edit-gallery-'+image.id"
    ref="galleryEditButton"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    left
    size="sm"
    variant="flat-primary"
    menu-class="w-300"
    class="dropdown-icon-wrapper"
  >
    <template #button-content>
      <feather-icon icon="Edit2Icon" />
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-dropdown-form
        class="py-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name EN"
          rules="required"
        >
          <b-form-group
            label="Name EN"
            label-for="name_en"
          >
            <b-form-input
              :id="'name_en'+image.id"
              v-model="imageData.name.en"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Name IT"
          rules="required"
        >
          <b-form-group
            label="Name IT"
            label-for="name_it"
          >
            <b-form-input
              :id="'name_it'+image.id"
              v-model="imageData.name.it"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Save
          </b-button>

        </div>
      </b-dropdown-form>
    </validation-observer>

  </b-dropdown>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'GalleryEditButton',
  components: {
    BButton,
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankImageData = {
      id: props.image.id,
      position: props.image.position,
      visible: props.image.visible,
      name: {
        en: props.image.name.en,
        it: props.image.name.it,
      },
    }

    const imageData = ref(JSON.parse(JSON.stringify(blankImageData)))
    const resetimageData = () => {
      imageData.value = JSON.parse(JSON.stringify(blankImageData))
    }

    const onSubmit = () => {
      imageData.value = {
        id: props.image.id,
        position: props.image.position,
        visible: props.image.visible,
        name: {
          en: imageData.value.name.en,
          it: imageData.value.name.it,
        },
      }
      emit('image-name-update', imageData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetimageData)

    return {
      imageData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
