<template>
  <div>

    <b-card
      v-if="getCurrentItem().images.smallww.length > 0"
    >
      <b-row>
        <!-- draggable -->
        <draggable
          v-model="getCurrentItem().images.smallww"
          tag="ul"
          class="cursor-move row"
          @change="updateOrderGallery($event)"
        >

          <b-col
            v-for="image in getCurrentItem().images.smallww"
            :key="image.id"
            md="4"
            class="mb-1"
          >

            <b-img-lazy
              :src="image.link"
              thumbnail
              fluid
            />
            <image-edit-area
              v-if="image.id"
              :image="image"
              @refetch-data="refetchItem"
              @deleted-image="refetchItem"
            />

          </b-col>
        </draggable>
      </b-row>
    </b-card>

    <b-card>
      <b-col>
        <vue-dropzone
          id="dropzone"
          ref="dropzone"
          :options="dropzoneOptions"
          @vdropzone-queue-complete="imagesUploaded"
        />
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BImgLazy, BCol, BCard, BRow,
} from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ImageEditArea from '@/views/items/items/items-edit/ImageEditArea.vue'
import useItemList from '@/views/items/items/useItemList'
import draggable from 'vuedraggable'
import store from '@/store'

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SectionAddGalleryItem',
  components: {
    ImageEditArea,

    BImgLazy,
    BCol,
    BCard,
    BRow,

    draggable,

    vueDropzone: vue2Dropzone,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { refs }) {
    const {
      getCurrentItem,
      refetchItem,
    } = useItemList()

    const imagesUploaded = () => {
      refs.dropzone.removeAllFiles()
      refetchItem()
    }

    return {
      getCurrentItem,
      refetchItem,
      imagesUploaded,
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_APIURL}staff/product-sheet/galleries`,
        thumbnailWidth: 200,
        maxFilesize: 60,
        timeout: 180000,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        params: {
          product_id: this.itemData.id,
        },
        paramName: 'image',
      },
    }
  },
  methods: {
    resolveOrderOfImages() {
      return this.getCurrentItem()
        .images
        .smallww
        .map((data, index) => ({ index, id: data.id }))
    },
    updateOrderGallery(movedItem) {
      const image = movedItem.moved.element
      image.image_order = this.resolveOrderOfImages()
      store.dispatch('items/updateImage', image)
        .then(() => {
          this.refetchItem()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    },
  },
}
</script>

<style scoped>

</style>
