<template>
  <div class="demo-inline-spacing">
    <div
      class="btn-toolbar justify-content-between mt-1"
    >
      <div
        class="btn-group"
      >

        <b-form-checkbox
          v-model="imageData.visible"
          checked="true"
          class="custom-control-success"
          name="visible"
          switch
          @change="onSubmit"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="PauseIcon" />
          </span>
        </b-form-checkbox>

        <a
          ref="swalConfirmDeleteImage"
          class="text-danger ml-1"
          @click="swalConfirmDeleteImage(imageData.id)"
        >
          <feather-icon icon="Trash2Icon" />
        </a>

        <gallery-edit-button
          class="ml-1"
          :image="imageData"
          @image-name-update="onSubmit"
        />

      </div>
    </div>
    <b-badge
      v-if="image.position"
      variant="light-primary"
    >
      {{ image.position }}
    </b-badge>
  </div>
</template>
<script>
import GalleryEditButton from '@/views/items/items/items-edit/GalleryEditButton.vue'
import { BFormCheckbox, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useItemList from '@/views/items/items/useItemList'
import store from '@/store'
import { ref } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ImageEditArea',
  components: {
    GalleryEditButton,
    BFormCheckbox,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankImageData = {
      id: props.image.id,
      visible: !!props.image.visible,
      name: {
        en: props.image.name.en,
        it: props.image.name.it,
      },
    }

    const imageData = ref(JSON.parse(JSON.stringify(blankImageData)))
    const resetimageData = () => {
      imageData.value = JSON.parse(JSON.stringify(blankImageData))
    }

    const onSubmit = e => {
      if (typeof e === 'object' && e !== null) {
        imageData.value = e
      }
      store.dispatch('items/updateImage', imageData.value)
        .then(() => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Image updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      positionOption,
    } = useItemList()

    return {
      imageData,
      onSubmit,
      resetimageData,
      positionOption,
    }
  },
  methods: {
    swalConfirmDeleteImage(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('items/forceDeleteImage', id)
              .then(() => {
                this.$emit('deleted-image')
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Image has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Image is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style scoped>

</style>
