<template>
  <validation-observer
    v-if="getCurrentTypes() && filtersData"
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      ref="form"
      :style="{height: trHeight}"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row class="match-height">

        <b-col
          lg="6"
          md="6"
        >
          <b-card
            no-body
            class="card-employee-task"
          >

            <b-card-header>
              <b-card-title>
                {{ filtersData.group.name.en }} > {{ filtersData.category.name.en }} > {{ filtersData.subcategory.name.en }}
                <b-card-sub-title class="mt-1">
                  {{ filtersData.group.name.it }} > {{ filtersData.category.name.it }} > {{ filtersData.subcategory.name.it }}
                </b-card-sub-title>
              </b-card-title>
              <feather-icon
                v-b-modal.modal-danger
                icon="SettingsIcon"
                size="18"
                class="cursor-pointer"
              />
              <b-modal
                id="modal-danger"
                ok-only
                ok-variant="danger"
                ok-title="Save"
                modal-class="modal-danger"
                centered
                title="Change sub category"
                button-size="sm"
                @ok="onSubmit"
              >
                <b-card-text>
                  Attention if you change sub category, all filters will be deleted.
                </b-card-text>
                <v-select
                  v-if="Object.keys(getCurrentCategoriesById()).length > 0"
                  v-model="itemData.subcategory_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="period"
                  :reduce="val => val.value"
                  :options="resolveSubCategory(filtersData.category.id)"
                  :clearable="false"
                />
              </b-modal>
            </b-card-header>

            <b-card-body>
              <b-row>
                <b-col
                  cols="5"
                  lg="4"
                  md="5"
                  sm="6"
                >
                  <b-img-lazy
                    fluid
                    style="height: auto; width: auto"
                    :src="itemData.images.smallww.length > 0 ? itemData.images.smallww[0].link : require('@/assets/images/placeholder/the_cube_placeholder.png')"
                    alt="Card image cap"
                  />
                </b-col>
                <b-col
                  cols="7"
                  lg="8"
                  md="7"
                  sm="6"
                >
                  <b-row>
                    <b-col
                      lg="6"
                      md="12"
                      sm="12"
                    >

                      <!-- Code -->
                      <b-form-group
                        label="Code"
                        label-for="code"
                      >
                        <b-form-input
                          id="code"
                          v-model="itemData.code"
                          placeholder="Code"
                          readonly
                        />

                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="6"
                      md="12"
                      sm="12"
                    >

                      <!-- Status -->
                      <validation-provider
                        #default="validationContext"
                        name="status"
                        rules="required"
                      >
                        <b-form-group
                          label="Status *"
                          label-for="status"
                        >
                          <div class="form-label-group">
                            <v-select
                              v-model="itemData.status"
                              :options="status"
                              :reduce="val => val.value"
                              input-id="status"
                              :clearable="false"
                            >
                              <template #search="{attributes, events}">
                                <input
                                  class="vs__search"
                                  :required="itemData.status == null"
                                  v-bind="attributes"
                                  v-on="events"
                                >
                              </template>
                            </v-select>
                          </div>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col md="12">

                      <!-- Title EN -->
                      <validation-provider
                        #default="validationContext"
                        name="Title EN"
                        rules="required"
                      >
                        <b-form-group
                          label="Title EN *"
                          label-for="title_en"
                        >
                          <b-form-input
                            id="title_en"
                            v-model="itemData.title.en"
                            v-b-tooltip.hover.v-primary
                            placeholder="Title EN"
                            title="period-specification-item-material-colors"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                    <b-col md="12">

                      <!-- Title IT -->
                      <validation-provider
                        #default="validationContext"
                        name="Title IT"
                        rules="required"
                      >
                        <b-form-group
                          label="Title IT *"
                          label-for="title_it"
                        >
                          <b-form-input
                            id="title_it"
                            v-model="itemData.title.it"
                            v-b-tooltip.hover.v-primary
                            placeholder="Title IT"
                            title="prodotto-specifica-materiali-colori-periodo"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      lg="12"
                      md="12"
                      sm="12"
                    >

                      <template v-if="itemData.rent_status !== 'rented' && itemData.rent_status !== 'rent_requested'">
                        <!-- Rent Status -->
                        <validation-provider
                          #default="validationContext"
                          name="rent_status"
                          rules="required"
                        >
                          <b-form-group
                            label="Rent Status"
                            label-for="rent_status"
                          >
                            <div class="form-label-group">
                              <v-select
                                v-model="itemData.rent_status"
                                :options="rentStatus"
                                :reduce="val => val.value"
                                input-id="rent_status"
                                :clearable="false"
                                :disabled="itemData.rent_status === 'rented' || itemData.rent_status === 'rent_requested'"
                              >
                                <template #search="{attributes, events}">
                                  <input
                                    class="vs__search"
                                    :required="itemData.rent_status == null"
                                    v-bind="attributes"
                                    v-on="events"
                                  >
                                </template>
                              </v-select>
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </template>
                      <template v-else>
                        <label>
                          Rent Status:
                        </label>
                        <p class="text-danger text-capitalize">
                          {{ itemData.rent_status.replace('_', ' ') }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-card
            no-body
            class="card-employee-task"
          >

            <b-card-header>
              <b-card-title>
                General Info
              </b-card-title>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                size="sm"
                @click="downloadQrItem(itemData)"
              >
                <span class="align-middle">QR</span>
                <feather-icon
                  icon="ArrowDownIcon"
                  class="ml-50"
                />
              </b-button>
            </b-card-header>

            <b-card-body>
              <b-row>
                <b-col md="6">

                  <!-- Period -->
                  <validation-provider
                    #default="validationContext"
                    name="period"
                    rules=""
                  >
                    <b-form-group
                      label="Period"
                      label-for="period"
                    >
                      <v-select
                        v-if="periodsOptions"
                        v-model="itemData.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        taggable
                        push-tags
                        placeholder="Add Period"
                        input-id="period"
                        :reduce="val => val.label"
                        :options="periodsOptions"
                        :clearable="true"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">

                  <!-- Brand -->
                  <validation-provider
                    #default="validationContext"
                    name="brand"
                    rules=""
                  >
                    <b-form-group
                      label="Brand"
                      label-for="brand"
                    >

                      <v-select
                        v-if="brandsOptions"
                        v-model="itemData.brand"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        taggable
                        push-tags
                        placeholder="Add Brand"
                        input-id="brand"
                        :reduce="brandsOptions.length > 0 ? val => val.label : val => val"
                        :options="brandsOptions"
                        :clearable="true"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col md="6">

                  <!-- Size -->
                  <validation-provider
                    #default="validationContext"
                    name="size"
                    rules=""
                  >
                    <b-form-group
                      label="Size"
                      label-for="size"
                    >
                      <v-select
                        v-if="sizesOptions"
                        v-model="itemData.size"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Size"
                        input-id="size"
                        :reduce="val => val.label"
                        :options="sizesOptions"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">

                  <!-- Gender -->
                  <validation-provider
                    #default="validationContext"
                    name="gender"
                    rules=""
                  >
                    <b-form-group
                      label="Gender"
                      label-for="gender"
                    >
                      <div class="form-label-group">
                        <v-select
                          v-model="itemData.gender"
                          :options="genders"
                          :reduce="val => val.value"
                          input-id="gender"
                        />
                      </div>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">

                  <!-- Location -->
                  <validation-provider
                    #default="validationContext"
                    name="location"
                    rules=""
                  >
                    <b-form-group
                      label="Location"
                      label-for="location"
                    >

                      <v-select
                        v-if="locationsOptions"
                        v-model="itemData.location"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        taggable
                        push-tags
                        placeholder="Add Location"
                        input-id="location"
                        :reduce="locationsOptions.length > 0 ? val => val.label : val => val"
                        :options="locationsOptions"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6" />
                <b-col
                  md="4"
                  sm="6"
                  class="mt-2"
                >
                  {{ itemData.special_price ? 'Special item' : itemData.gems ? 'Gems item' : 'Normal item'
                  }}
                  <strong>€ {{ itemData.special_price ? itemData.special_price : itemData.gems ?
                    filtersData.category.gems_price : filtersData.category.price }}</strong>
                </b-col>
                <b-col
                  md="2"
                  sm="6"
                >
                  <label>{{ itemData.gems ? 'Gems Price' : 'Price' }}</label>

                  <b-form-checkbox
                    v-model="itemData.gems"
                    checked="true"
                    class="custom-control-success"
                    name="multiple"
                    switch
                    @change="itemData.special_price = null"
                  >
                    <span class="switch-icon-left">
                      G
                    </span>
                  </b-form-checkbox>
                </b-col>
                <b-col md="6">

                  <!-- Special Price -->
                  <validation-provider
                    #default="validationContext"
                    name="special_price"
                    rules="regex:^([0-9]+)$"
                  >
                    <b-form-group
                      label="Special Price"
                      label-for="special_price"
                    >
                      <b-input-group
                        prepend="€"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="special_price"
                          v-model="itemData.special_price"
                          v-b-tooltip.hover.v-warning
                          title="Apply this price only if it is a special product"
                          placeholder="Special Price"
                          :state="getValidationState(validationContext)"
                          @input="itemData.gems = false"
                        />

                      </b-input-group>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="12"
          md="6"
        >
          <b-card v-if="getCurrentTypes() && filtersData">
            <b-row
              v-for="(cat, index) in filtersData"
              :key="cat.id"
            >
              <b-col
                v-if="index === 'category' && cat.types.length > 0"
                md="12"
              >
                <b-card-title>Category Filters</b-card-title>
              </b-col>
              <b-col
                v-else-if="index === 'subcategory' && cat.types.length > 0"
                md="12"
                class="mt-2"
              >
                <b-card-title>Sub Category Filters</b-card-title>
              </b-col>

              <template v-if="cat.types && cat.types.length > 0">
                <b-col
                  v-for="(type) in cat.types"
                  :key="type.id"
                  md="4"
                >

                  <b-form-group
                    v-if="getCurrentTypes()[type]"
                    :label="getCurrentTypes()[type].name.en"
                    :label-for="getCurrentTypes()[type].name.en"
                  >
                    <div class="form-label-group">
                      <v-select
                        v-model="itemData.filters[type]"
                        :options="resolveDataForVSelect(type)"
                        :reduce="val => val.value"
                        :input-id="getCurrentTypes()[type].name.en"
                        :multiple="getCurrentTypes()[type].multiple === 1"
                        :required="getCurrentTypes()[type].required === 1"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="getCurrentTypes()[type].required === 1 && (itemData.filters[type] == null || itemData.filters[type].length === 0) && resolveDataForVSelect(type).length > 0"
                            v-bind="attributes"
                            v-on="events"
                          >
                        </template>
                      </v-select>
                    </div>
                  </b-form-group>

                </b-col>
              </template>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          lg="12"
          md="12"
        >
          <b-card
            v-if="itemData.damages"
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>
                Damages
              </b-card-title>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-2"
                size="sm"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add New</span>
              </b-button>
            </b-card-header>
            <b-card-body>
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in itemData.damages"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Item Name -->
                <b-col>
                  <b-row>
                    <b-col md="2">
                      <b-form-group>
                        <label>
                          Date
                        </label>
                        <flat-pickr
                          v-model="itemData.damages[index].date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Cost -->
                    <b-col md="10">
                      <b-form-group
                        label="Description"
                        label-for="description"
                      >
                        <b-form-textarea
                          v-model="itemData.damages[index].description"
                          no-resize
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      v-if="itemData.damages.length > 1"
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        size="sm"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>Delete</span>
                      </b-button>
                    </b-col>
                    <b-col v-if="itemData.damages.length > 1">
                      <hr>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="9"
          md="6"
        >
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>
                Notes
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <!-- Row Loop -->
              <b-row>
                <b-col>
                  <b-row>
                    <!-- Notes -->
                    <b-col md="12">
                      <b-form-group>
                        <b-form-textarea
                          v-model="itemData.notes"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="d-block"
          lg="3"
          md="2"
        >
          <b-card>
            <b-row>
              <!-- submit and reset -->
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="submit"
                  variant="success"
                  class="btn-block"
                >
                  Update
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea,
  BInputGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  VBTooltip,
  BFormCheckbox,
  BImgLazy,
  BCardSubTitle,
  BModal,
  VBModal,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import useItemList from '@/views/items/items/useItemList'
import useTypesList from '@/views/items/filters/filters-list/useTypesList'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import { heightTransition } from '@core/mixins/ui/transition'
import flatPickr from 'vue-flatpickr-component'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SectionEditItem',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    BInputGroup,
    vSelect,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCard,
    BFormCheckbox,
    BImgLazy,
    BCardSubTitle,
    BModal,
    BCardText,

    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    group: {
      type: Object,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    subcategory: {
      type: Object,
      required: true,
    },
    editItemData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Use toast
    const toast = useToast()

    const blankFiltersData = {
      group: props.group,
      category: props.category,
      subcategory: props.subcategory,
    }

    const nextTodoId = props.editItemData.damages[props.editItemData.damages.length - 1].id

    const blankItemData = {
      id: props.editItemData.id,
      subcategory_id: props.editItemData.subcategory.id,
      code: props.editItemData.code,
      title: {
        en: props.editItemData.title.en,
        it: props.editItemData.title.it,
      },
      year: props.editItemData.year,
      brand: props.editItemData.brand,
      size: props.editItemData.size,
      special_price: props.editItemData.type_price === 'special' ? props.editItemData.price : null,
      gems: props.editItemData.type_price === 'gems',
      gender: props.editItemData.gender,
      location: props.editItemData.location,
      status: props.editItemData.status,
      rent_status: props.editItemData.rent_status,
      filters: Object.keys(props.editItemData.filters).length > 0 ? props.editItemData.filters : {},
      damages: props.editItemData.damages,
      notes: props.editItemData.notes,
      images: props.editItemData.images,
      manual_code: 1,
    }

    const filtersData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetitemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
    }

    const submitType = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetitemData)

    const onSubmit = () => {
      store.dispatch('items/updateItem', itemData.value)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          if (props.editItemData.subcategory.id !== itemData.value.subcategory_id) {
            window.location.reload()
          }
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      fetchTypes,
      getCurrentTypes,
      mapbyid,
    } = useTypesList()

    const {
      fetchItems,
      downloadQrItem,
      getDraftItem,
      genders,
      status,
      rentStatus,
      periodsOptions,
      brandsOptions,
      locationsOptions,
      sizesOptions,
    } = useItemList()

    fetchItems()

    const {
      fetchCategories,
      getCurrentCategories,
      fetchCategoriesById,
      getCurrentCategoriesById,
    } = useCategoriesList()

    return {
      downloadQrItem,
      periodsOptions,
      brandsOptions,
      locationsOptions,
      sizesOptions,
      nextTodoId,
      // Validation
      refFormObserver,
      getValidationState,
      resetForm,
      //
      // Types
      fetchTypes,
      getCurrentTypes,
      mapbyid,
      //

      submitType,
      onSubmit,
      itemData,
      filtersData,
      fetchCategories,
      getCurrentCategories,
      fetchCategoriesById,
      getCurrentCategoriesById,
      getDraftItem,
      genders,
      status,
      rentStatus,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  mounted() {
    this.mapbyid = true

    if (this.$can('update', 'Product')) {
      this.fetchTypes()
      this.fetchCategoriesById()
    }

    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    buildCategoriesForSelect(array) {
      const newArray = []
      array.forEach(key => {
        newArray.push({
          value: key.value,
          title: key.label.en,
        })
      })
      return newArray
    },
    resolveDataForVSelect(index) {
      return this.getCurrentTypes()[index].filters.map(data => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: data.id,
      }))
    },

    resolveSubCategory(index) {
      return this.getCurrentCategoriesById()[index].map(data => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: data.id,
      }))
    },

    repeateAgain() {
      this.itemData.damages.push({
        id: this.nextTodoId += 1,
        date: null,
        description: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.itemData.damages.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
    .repeater-form {
        overflow: hidden;
        transition: .35s height;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
