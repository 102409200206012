<template>

  <div v-if="itemData">
    <!-- Tab: Product -->
    <b-card>
      <b-button
        v-if="$can('update', 'Product')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="tab === 'general' ? 'primary' : 'flat-primary'"
        @click="tab = 'general'"
      >
        <feather-icon
          icon="TagIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">General</span>
      </b-button>
      <b-button
        v-if="$can('store', 'Gallery')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="tab === 'gallery' ? 'primary' : 'flat-primary'"
        class="ml-1"
        @click="tab = 'gallery'"
      >
        <feather-icon
          icon="ImageIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Images</span>
      </b-button>
    </b-card>

    <div
      v-show="tab === 'general'"
    >
      <section-edit-item
        :group="itemData.group"
        :category="itemData.category"
        :subcategory="itemData.subcategory"
        :edit-item-data="itemData"
      />
    </div>

    <!-- Tab: Gallery -->
    <div
      v-show="$can('store', 'Gallery') && tab === 'gallery'"
    >
      <section-add-gallery-item
        :item-data="itemData"
      />
    </div>

  </div>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import SectionEditItem from '@/views/items/items/items-edit/SectionEditItem.vue'
import SectionAddGalleryItem from '@/views/items/items/items-edit/SectionAddGalleryItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ItemEdit',

  components: {
    SectionAddGalleryItem,
    SectionEditItem,
    BCard,
    BButton,

  },
  directives: {
    Ripple,
  },
  setup() {
    const tab = ref(null)
    const itemData = ref(null)

    store.dispatch('items/fetchItem', { id: router.currentRoute.params.id })
      .then(response => { itemData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          itemData.value = undefined
        }
      })

    return {
      tab,
      itemData,
    }
  },
  created() {
    this.tab = this.$route.hash === '#gallery' ? 'gallery' : 'general'
  },
}
</script>

<style scoped>

</style>
