import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, watch } from '@vue/composition-api'

export default function useTypesList() {
  const toast = useToast()

  const refTypesListTable = ref(null)

  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const mapbyid = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: 'general', value: 'published', sortable: true },
    { key: 'family_filters', value: 'name', sortable: true },
    { key: 'info' },
    { key: 'category' },
    { key: 'related_items', value: 'location' },
    { key: 'actions' },
  ]

  const fetchTypesCallBack = (ctx, callback) => {
    let sorterResolved = sortBy.value
    if (sorterResolved !== 'id' && tableColumns.find(key => key.key === sorterResolved).value !== sorterResolved) {
      sorterResolved = tableColumns.find(key => key.key === sorterResolved).value
    }
    store
      .dispatch('types/fetchTypes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sorterResolved,
        sortDesc: isSortDirDesc.value,
        mapbyid: mapbyid.value,
      })
      .then(response => {
        const { data } = response.data
        callback(data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching types',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchTypes = () => {
    store
      .dispatch('types/fetchTypes', {
        q: searchQuery.value,
        mapbyid: mapbyid.value,
      })
      .then(response => {
        const { data } = response.data
        return data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching types',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    refTypesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const getCurrentTypes = () => store.getters['types/getCurrentTypes']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTypeToTranslateVariant = toTranslate => {
    if (toTranslate === 0) {
      return {
        message: 'Translated',
        class: 'success',
      }
    }
    if (toTranslate === 1) {
      return {
        message: 'To Translate',
        class: 'warning',
      }
    }
    return 'primary'
  }

  return {
    refTypesListTable,
    sortBy,
    isSortDirDesc,
    perPageOptions,
    searchQuery,
    tableColumns,
    mapbyid,

    fetchTypesCallBack,
    fetchTypes,
    getCurrentTypes,
    refetchData,
    resolveTypeToTranslateVariant,
  }
}
