import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useItemsList() {
  // Use toast
  const toast = useToast()

  const refItemsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'code',
      value: 'code',
      sortable: false,
    },
    {
      key: 'title',
      value: 'title',
      sortable: false,
    },
    {
      key: 'category',
      value: 'subcategory_id',
      sortable: false,
    },
    { key: 'price' },
    {
      key: 'brand',
      value: 'brand',
      sortable: false,
    },
    {
      key: 'location',
      value: 'location',
      sortable: false,
    },
    {
      key: 'status',
      value: 'status',
      sortable: false,
    },
    { key: 'images' },
    { key: 'actions' },
  ]

  const orderBy = [
    {
      label: 'Code recent',
      value: 'code_desc',
    },
    {
      label: 'Code',
      value: 'code',
    },
    {
      label: 'Created at recent',
      value: 'created_at_desc',
    },
    {
      label: 'Created at',
      value: 'created_at',
    },
    {
      label: 'Updated at recent',
      value: 'updated_at_desc',
    },
    {
      label: 'Updated at',
      value: 'updated_at',
    },
    {
      label: 'Period recent',
      value: 'year_desc',
    },
    {
      label: 'Period',
      value: 'year',
    },
    {
      label: 'Price higher',
      value: 'price_desc',
    },
    {
      label: 'Price',
      value: 'price',
    },
  ]

  const prices = [
    {
      label: 'Normal',
      value: 'normal',
    },
    {
      label: 'Gems',
      value: 'gems',
    },
    {
      label: 'Special',
      value: 'special',
    },
  ]

  const genders = [
    {
      label: 'M',
      value: 'M',
    },
    {
      label: 'F',
      value: 'F',
    },
    {
      label: 'U',
      value: 'U',
    },
  ]

  const status = [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Published',
      value: 'published',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
  ]

  const rentStatus = [
    {
      label: 'Available',
      value: 'available',
    },
    {
      label: 'Internal Rented',
      value: 'internal_rented',
    },
    {
      label: 'Not Available',
      value: 'not_available',
    },
  ]

  const positionOption = [
    {
      label: 'Front - Davanti',
      value: 'front',
    },
    {
      label: 'Back - Dietro',
      value: 'back',
    },
    {
      label: 'Detail - Dettaglio',
      value: 'detail',
    },
  ]

  const sizesOptions = [
    {
      label: 'XXXS',
      value: 'xxxs',
    },
    {
      label: 'XXS',
      value: 'xxs',
    },
    {
      label: 'XS',
      value: 'xs',
    },
    {
      label: 'S',
      value: 's',
    },
    {
      label: 'M',
      value: 'm',
    },
    {
      label: 'L',
      value: 'l',
    },
    {
      label: 'XL',
      value: 'xl',
    },
    {
      label: 'XXL',
      value: 'xxl',
    },
    {
      label: 'XXXL',
      value: 'xxxl',
    },
    {
      label: '24',
      value: '24',
    },
    {
      label: '25',
      value: '25',
    },
    {
      label: '26',
      value: '26',
    },
    {
      label: '27',
      value: '27',
    },
    {
      label: '28',
      value: '28',
    },
    {
      label: '29',
      value: '29',
    },
    {
      label: '30',
      value: '30',
    },
    {
      label: '31',
      value: '31',
    },
    {
      label: '32',
      value: '32',
    },
    {
      label: '33',
      value: '33',
    },
    {
      label: '34',
      value: '34',
    },
    {
      label: '35',
      value: '35',
    },
    {
      label: '36',
      value: '36',
    },
    {
      label: '37',
      value: '37',
    },
    {
      label: '38',
      value: '38',
    },
    {
      label: '39',
      value: '39',
    },
    {
      label: '40',
      value: '40',
    },
    {
      label: '41',
      value: '41',
    },
    {
      label: '42',
      value: '42',
    },
    {
      label: '43',
      value: '43',
    },
    {
      label: '44',
      value: '44',
    },
    {
      label: '45',
      value: '45',
    },
    {
      label: '46',
      value: '46',
    },
    {
      label: '47',
      value: '47',
    },
    {
      label: '48',
      value: '48',
    },
    {
      label: '49',
      value: '49',
    },
    {
      label: '50',
      value: '50',
    },
    {
      label: '51',
      value: '51',
    },
    {
      label: '52',
      value: '52',
    },
    {
      label: '52',
      value: '52',
    },
    {
      label: '53',
      value: '53',
    },
    {
      label: '54',
      value: '54',
    },
    {
      label: '55',
      value: '55',
    },
    {
      label: '56',
      value: '56',
    },
    {
      label: '57',
      value: '57',
    },
    {
      label: '58',
      value: '58',
    },
  ]

  if (JSON.parse(localStorage.getItem('filterSetting')) === null) {
    console.log('non devo essere qui')
    localStorage.setItem('filterSetting', JSON.stringify({
      currentPage: 1,
      searchQuery: null,
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      genderFilter: null,
      priceFilter: null,
      orderByFilter: null,
      locationFilter: null,
      isSortDirDesc: false,
      groupFilter: null,
      categoryFilter: null,
      subCategoryFilter: null,
      typeFilter: null,
      filterFilter: null,
    }))
  }

  const filterSetting = ref(JSON.parse(localStorage.getItem('filterSetting')))
  const perPage = ref(24)
  const totalItems = ref(0)
  const periodsOptions = ref(null)
  const brandsOptions = ref(null)
  const locationsOptions = ref([])
  const currentPage = ref(filterSetting.value.currentPage || 1)
  const perPageOptions = [48, 96, 192]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(filterSetting.value.isSortDirDesc || false)
  const roleFilter = ref(filterSetting.value.roleFilter || null)
  const planFilter = ref(filterSetting.value.planFilter || null)
  const priceFilter = ref(filterSetting.value.priceFilter || null)
  const genderFilter = ref(filterSetting.value.genderFilter || null)
  const statusFilter = ref(filterSetting.value.statusFilter || null)
  const orderByFilter = ref(filterSetting.value.orderByFilter || null)
  const locationFilter = ref(filterSetting.value.locationFilter || null)
  // Filtri per categoria
  const groupFilter = ref(filterSetting.value.groupFilter || null)
  const categoryFilter = ref(filterSetting.value.categoryFilter || null)
  const subCategoryFilter = ref(filterSetting.value.subCategoryFilter || null)
  //
  // Filtri per type filter
  const typeFilter = ref(filterSetting.value.typeFilter || null)
  const filterFilter = ref(filterSetting.value.filterFilter || null)
  //

  const dataMeta = computed(() => {
    const localItemsCount = refItemsListTable.value ? refItemsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const fetchItems = (ctx, callback) => {
    let sorterResolved = sortBy.value
    if (sorterResolved !== 'id' && tableColumns.find(key => key.key === sorterResolved).value !== sorterResolved) {
      sorterResolved = tableColumns.find(key => key.key === sorterResolved).value
    }
    store
      .dispatch('items/fetchItems', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: orderByFilter.value != null ? orderByFilter.value : sorterResolved,
        location: locationFilter.value,
        sortDesc: isSortDirDesc.value,
        prices: priceFilter.value,
        gender: genderFilter.value,
        status: statusFilter.value,
        group: groupFilter.value != null ? groupFilter.value.id : null,
        category: groupFilter.value != null && categoryFilter.value != null && categoryFilter.value.length ? categoryFilter.value.map(data => (data.id)) : null,
        subcategory: groupFilter.value != null && categoryFilter.value != null && subCategoryFilter.value != null && categoryFilter.value.length ? subCategoryFilter.value.map(data => (data.id)) : null,
        type: typeFilter.value != null ? typeFilter.value.id : null,
        filters: typeFilter.value != null && filterFilter.value != null && filterFilter.value.length ? filterFilter.value.map(data => (data.id)) : null,
      })
      .then(response => {
        const { products, meta } = response.data.data
        if (callback) {
          callback(products)
        }
        totalItems.value = meta.total
        periodsOptions.value = Object.values(meta.periods)
          .map(data => ({ label: data }))
        brandsOptions.value = Object.values(meta.brands)
          .map(data => ({ label: data }))
        locationsOptions.value = Object.values(meta.locations)
          .map(data => ({ label: data }))
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching items',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    // if (refItemsListTable.value) {
    //   console.log('ref')
    //   refItemsListTable.value.refresh()
    // } else {
    //   console.log('non ref')
    //   store.commit('items/setCurrentPagination', { currentPage: currentPage.value })
    //   fetchIte ms()
    // }
    fetchItems()
    // if (refItemsListTable.value) {
    //   refItemsListTable.value.refresh()
    // }
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, genderFilter, priceFilter, orderByFilter, locationFilter, isSortDirDesc, groupFilter, categoryFilter, subCategoryFilter, typeFilter, filterFilter], () => {
    localStorage.setItem('filterSetting', JSON.stringify({
      currentPage: currentPage.value,
      perPage: perPage.value,
      searchQuery: searchQuery.value,
      roleFilter: roleFilter.value,
      planFilter: planFilter.value,
      statusFilter: statusFilter.value,
      genderFilter: genderFilter.value,
      priceFilter: priceFilter.value,
      orderByFilter: orderByFilter.value,
      locationFilter: locationFilter.value,
      isSortDirDesc: isSortDirDesc.value,
      subCategoryFilter: groupFilter.value && categoryFilter.value && categoryFilter.value.length ? subCategoryFilter.value : null,
      categoryFilter: groupFilter.value ? categoryFilter.value : null,
      groupFilter: groupFilter.value,
      typeFilter: typeFilter.value,
      filterFilter: typeFilter.value && filterFilter.value != null && filterFilter.value.length ? filterFilter.value : null,
    }))
    filterSetting.value = JSON.parse(localStorage.getItem('filterSetting'))
    refetchData()
  })

  const refetchItem = () => {
    store.dispatch('items/fetchItem', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching item',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadQrItem = itemData => {
    store.dispatch('items/downloadQr', itemData)
      .then(() => {})
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error downloading Qr',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListItems = () => store.getters['items/getListItems']
  const getDraftItem = () => store.getters['items/getDraftItem']
  const getCurrentItem = () => store.getters['items/getCurrentItem']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const typePriceResolver = (typePrice, needNormal = true) => {
    if (typePrice === 'special') return 'warning'
    if (typePrice === 'gems') return 'info'
    if (needNormal === false) return ''
    return 'secondary' // Se di tipo normal
  }

  const statusResolver = currentStatus => {
    if (currentStatus === 'Pending') return 'danger'
    if (currentStatus === 'Draft') return 'warning'
    if (currentStatus === 'Published') return 'success'
    return 'secondary' // Se archived
  }

  const rentStatusResolver = currentStatus => {
    const currentStatusLowerCase = currentStatus.toLowerCase()
    if (currentStatusLowerCase === 'available') return 'success'
    if (currentStatusLowerCase === 'rent_requested') return 'warning'
    if (currentStatusLowerCase === 'rented') return 'danger'
    if (currentStatusLowerCase === 'internal_rented') return 'secondary'
    if (currentStatusLowerCase === 'not_available') return 'danger'
    return 'secondary'
  }

  const rentStatusTextResolver = currentStatus => {
    const currentStatusLowerCase = currentStatus.toLowerCase()
    if (currentStatusLowerCase === 'available') return 'Available'
    if (currentStatusLowerCase === 'rent_requested') return 'Rent Requested'
    if (currentStatusLowerCase === 'rented') return 'Rented'
    if (currentStatusLowerCase === 'internal_rented') return 'Internal Rented'
    if (currentStatusLowerCase === 'not_available') return 'Not Available'
    return 'secondary'
  }

  return {
    positionOption,
    brandsOptions,
    locationsOptions,
    sizesOptions,
    fetchItems,
    refetchItem,
    downloadQrItem,
    getListItems,
    getDraftItem,
    getCurrentItem,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    periodsOptions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refItemsListTable,

    // Extra Filters
    orderBy,
    prices,
    genders,
    status,
    rentStatus,

    priceFilter,
    genderFilter,
    statusFilter,
    orderByFilter,
    locationFilter,

    groupFilter,
    categoryFilter,
    subCategoryFilter,

    typeFilter,
    filterFilter,
    //

    refetchData,

    // UI
    typePriceResolver,
    statusResolver,
    rentStatusResolver,
    rentStatusTextResolver,
    //
  }
}
