import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, watch } from '@vue/composition-api'

export default function useCategoriesList() {
  const toast = useToast()

  const groupFilter = ref(1)
  const refCategoryListTable = ref(null)
  const searchQuery = ref('')
  const TypeFilter = ref('')
  const groupSelect = ref('')
  const categorySelect = ref('')

  watch([groupFilter, groupSelect, categorySelect], () => {
    // console.log(categorySelect)
  })

  const fetchCategories = () => {
    store
      .dispatch('categories/fetchCategories', {
        type: TypeFilter.value,
        q: searchQuery.value,
      })
      .then(response => {
        const { data } = response.data
        return data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCategoriesById = () => {
    store
      .dispatch('categories/fetchCategoriesById', {
        getbyid: true,
      })
      .then(response => {
        const { data } = response.data
        return data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getCurrentCategories = () => store.getters['categories/getCurrentCategories']
  const getCurrentCategoriesById = () => store.getters['categories/getCurrentCategoriesById']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCategoryToTranslateVariant = toTranslate => {
    if (toTranslate === 0) {
      return {
        message: 'Translated',
        class: 'success',
      }
    }
    if (toTranslate === 1) {
      return {
        message: 'To Translate',
        class: 'warning',
      }
    }
    return 'primary'
  }

  const resolveDataForVSelect = () => getCurrentCategories()[groupFilter.value].categories.map(data => ({ label: `${data.name.it} - ${data.name.en}`, value: data.id }))
  const resolveDataForFilter = () => getCurrentCategories().map((data, index) => ({ label: `${data.name.it} - ${data.name.en}`, value: index }))

  function resolveSubCategories(group = null, category = null) {
    if (Object.values(this.getCurrentCategories()).length > 0) {
      if (group === null) {
        return this.getCurrentCategories()
          .map((data, index) => ({
            label: `${data.name.it} - ${data.name.en}`,
            value: index,
            id: data.id,
          }))
      }
      if (category === null) {
        return this.getCurrentCategories()[group].categories.map((data, index) => ({
          label: `${data.name.it} - ${data.name.en}`,
          value: index,
          id: data.id,
        }))
      }
      return this.getCurrentCategories()[group].categories[category].subcategories.map((data, index) => ({
        label: `${data.name.it} - ${data.name.en}`,
        value: index,
        id: data.id,
      }))
    }
    return null
  }

  return {
    refCategoryListTable,
    searchQuery,
    groupSelect,
    categorySelect,
    TypeFilter,
    // FILTERS
    groupFilter,
    //
    fetchCategories,
    fetchCategoriesById,
    getCurrentCategories,
    getCurrentCategoriesById,
    resolveCategoryToTranslateVariant,
    resolveDataForVSelect,
    resolveDataForFilter,
    // For Select
    // resolveGroup,
    // resolveCategories,
    // resolveSubCategories,
    //
    resolveSubCategories,
  }
}
